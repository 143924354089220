<template>
  <base-view icon="grid" title="Matriz de Unidades administrativas">
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>

    <!-- Listado -->
    <check-authorization
      :requiresAuthorizations="['obtener matriz unidad administrativa prestacion']"
    >
      <matriz-unidades-administrativas-table></matriz-unidades-administrativas-table>
    </check-authorization>
  </base-view>
</template>

<script>
import { mapState } from 'vuex'
import MatrizUnidadesAdministrativasTable from '@/components/Catalogos/MatrizUnidadesAdministrativas/MatrizUnidadesAdministrativasTable.vue'

export default {
  name: 'MatrizUnidadesAdministrativasView',

  components: {
    MatrizUnidadesAdministrativasTable
  },

  computed: {
    ...mapState('matrizUnidadesAdministrativasModule', ['isCreatingResource'])
  }
}
</script>

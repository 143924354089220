<template>
  <div>
    <div class="card card-header-actions">
      <div class="card-header">
        Listado de matriz de unidades administrativas
      </div>
      <div class="card-body p-3">
        <x-select-periodo-fiscal @input="onChangePeriodoFiscal" class="mb-3" modulo="Matriz de unidades administrativas"/>

        <b-table
          sticky-header="700px"
          head-variant="light"
          no-border-collapse
          responsive
          striped
          bordered
          :fields="getFields"
          :items="getItems"
          v-if="isMatrizAvailable"
        >
          <template #thead-top>
            <b-tr>
              <b-th
                class="b-table-sticky-column"
                v-for="item in getHeader"
                :key="item"
                style="text-align: center;"
              >
                <div v-if="isNaN(item) || item === ''">{{ item }}</div>
                <div v-else>
                  {{ item }}
                  <b-form-checkbox
                    :checked="onLoad(item)"
                    :selector-clave="item"
                    @change="onActivar({ data: item, value: $event }, 'clave')">
                  </b-form-checkbox>
                </div>
              </b-th>
            </b-tr>
          </template>

          <template #cell()="data">
            <div v-if="data.value.prestacion_id">
              <b-form-checkbox
                :data-prestacion="data.item.Clave"
                :data-clave="data.value.clave"
                :data-prestacion-id="data.value.prestacion_id"
                :data-unidad-administrativa-id="data.value.unidad_administrativa_id"
                :checked="data.value.checked"
                @change="checked({ data: data.value, value: $event, clave: data.item.Clave })"
              ></b-form-checkbox>
            </div>
            <div v-else>
              <div v-if="isNaN(data.value)">
                {{ data.value }}
              </div>
              <div v-else style="align-items: center;">
                <div>
                  {{ data.value }}
                </div>
                <b-form-checkbox
                  :checked="data.item.TodosChecados"
                  :selector-prestacion="data.value"
                  @change="onActivar({ data: data.value, value: $event }, 'prestacion')"
                >
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </b-table>

        <loading v-else class="p-10"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MatrizUnidadesAdministrativas',

  data () {
    return {
      isMatrizAvailable: false,

      periodo_fiscal_id: null,

      prestacionesChecadas: false
    }
  },

  computed: {
    ...mapGetters('matrizUnidadesAdministrativasModule', ['getItems', 'getFields', 'getHeader'])
  },

  methods: {
    ...mapActions('matrizUnidadesAdministrativasModule', ['getAll']),

    async submit () {
      this.isMatrizAvailable = false
      const getval = await this.getAll(this.periodo_fiscal_id)
      this.$notify(getval, 'Matriz de unidades administrativas y prestaciones')
      this.isMatrizAvailable = true
    },

    checked (selected) {
      const action = selected.value ? 'create' : 'delete'

      const claves = document.querySelectorAll(`[data-clave="${selected.data.clave}"]`)
      const clavesChecadas = document.querySelectorAll(`[data-clave="${selected.data.clave}"]:checked`)
      document.querySelector(`[selector-clave="${selected.data.clave}"]`).checked = clavesChecadas.length === claves.length

      const prestaciones = document.querySelectorAll(`[data-prestacion="${selected.clave}"]`)
      const prestacionesChecadas = document.querySelectorAll(`[data-prestacion="${selected.clave}"]:checked`)
      document.querySelector(`[selector-prestacion="${selected.clave}"]`).checked = prestacionesChecadas.length === prestaciones.length

      this.dispatchAction(selected.data, action)
    },

    async dispatchAction (data, action, all = false) {
      if (all) {
        const { error, message } = await this.$store.dispatch(
          `matrizUnidadesAdministrativasModule/${action}All`,
          data
        )

        this.$notify({ error, message }, 'Matriz de unidades administrativas y prestaciones')

        if (error) this.submit()
      } else {
        const { error, message } = await this.$store.dispatch(
          `matrizUnidadesAdministrativasModule/${action}`,
          data
        )

        this.$notify({ error, message }, 'Matriz de unidades administrativas y prestaciones')

        if (error) this.submit()
      }
    },

    onChangePeriodoFiscal (id) {
      this.periodo_fiscal_id = id

      this.submit()
    },

    onActivar (selected, type) {
      const selectors = document.querySelectorAll(type === 'prestacion' ? `[data-prestacion="${selected.data}"]` : `[data-clave="${selected.data}"]`)

      const all = []
      const action = selected.value ? 'create' : 'delete'

      selectors.forEach(selector => {
        selected.value ? selector.checked = true : selector.checked = false
        const data = this.getDataFromSelector(selector)
        all.push(data)
      })

      this.dispatchAction(all, action, true)
    },

    getDataFromSelector (selector) {
      const data = {
        clave: selector.getAttribute('data-clave'),
        prestacion_id: selector.getAttribute('data-prestacion-id'),
        unidad_administrativa_id: selector.getAttribute('data-unidad-administrativa-id'),
        checked: selector.checked
      }
      return data
    },

    onLoad (clave) {
      let isAll = false

      const claves = document.querySelectorAll(`[data-clave="${clave}"]`)
      const clavesChecadas = document.querySelectorAll(`[data-clave="${clave}"]:checked`)

      isAll = claves.length === clavesChecadas.length

      return isAll
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .table > tbody > tr > td {
  text-align: center;
  min-width: 160px;
}

/deep/ .table > tbody > tr > th,
/deep/ .table > thead > tr > th {
  font-size: 12px;
  text-align: center;
}
</style>
